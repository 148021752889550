import { FC, useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';

import {
  StyledFacadeGradient,
  StyledFacadeImage,
  StyledFacadeTitle,
  StyledIframe,
  StyledPlayButton,
  VideoRatio,
} from '@custom-components/Video';
import { useDevice } from '@hooks/deviceContext';
import { I18nProvider, useTranslation } from '@i18n';
import { useLinkComponent } from '@link';
import { Heading, PageGrid, Stack, Text, TextLink } from '@sparky';

interface Props {
  title?: string;
  videoId: string;
}

const VIDEO_PARAMS = new URLSearchParams({
  autoplay: '1',
  rel: '0', // don't show related videos that are not from the same channel
});

export const Video: FC<Props> = ({ title, videoId }) => {
  const { isIOS } = useDevice();
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const checkCookieConsent = () => {
    const qubitConsent = Cookies.get('qubitconsent');
    const areCookiesAccepted = qubitConsent === 'Accepted';
    setCookiesAccepted(areCookiesAccepted);

    if (areCookiesAccepted && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      window.removeEventListener('storage', handleCookieChange);
    }
  };

  const handleCookieChange = () => {
    checkCookieConsent();
  };

  useEffect(() => {
    checkCookieConsent();

    if (cookiesAccepted !== true) {
      window.addEventListener('storage', handleCookieChange);
      intervalRef.current = setInterval(checkCookieConsent, 1_000);
    }

    return () => {
      window.removeEventListener('storage', handleCookieChange);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [cookiesAccepted]);

  const supportsAutoplay = !isIOS;

  // Set to false if autoplay after user interaction is not supported (looking at you, iOS 👀)
  //  There are plugins that try to fix this by utilising the YT API and running the playVideo() method once the iframe
  //  is loaded, but this no longer seems to work in iOS.. Therefore, we're foregoing the facade and any performance
  //  benefits and show the iframe directly.
  //
  // One of these plugins that attempts to fix this is Paul Irish's https://github.com/paulirish/lite-youtube-embed/blob/master/src/lite-yt-embed.js#L145
  //  A similar approach was tried for this component (https://github.com/eneco-online/eneco-dxp-frontend/pull/5223/commits/522a647e4554e3dcd66454f0f19ef52e45c71d6d)
  //  but, just like the lite-youtube-embed demo, didn't work in iOS.
  const [showIframe, setShowIframe] = useState(!supportsAutoplay);

  if (!videoId) {
    return null;
  }

  if (!cookiesAccepted) {
    return (
      <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
        <NoCookiesVideoComponent />
      </I18nProvider>
    );
  }

  const [sanitizedVideoId] = videoId.match(/^[a-zA-Z0-9_-]*/) ?? [videoId];
  const thumbnailUrl = `https://img.youtube.com/vi/${sanitizedVideoId}/sddefault.jpg`;

  return (
    <VideoRatio>
      {showIframe ? (
        <StyledIframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          id="youtube"
          loading="lazy"
          src={`https://www.youtube.com/embed/${sanitizedVideoId}?${VIDEO_PARAMS.toString()}`}
          title={title || 'Youtube Video player'}></StyledIframe>
      ) : (
        <>
          {title && (
            <>
              <StyledFacadeGradient />
              <StyledFacadeTitle href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank">
                {title}
              </StyledFacadeTitle>
            </>
          )}
          <StyledFacadeImage onClick={() => setShowIframe(true)} src={thumbnailUrl} alt={''} />
          <StyledPlayButton viewBox="0 0 68 48" version="1.1">
            <path
              d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
              fill="#f00"></path>
            <path d="M 45,24 27,14 27,34" fill="#fff"></path>
          </StyledPlayButton>
        </>
      )}
    </VideoRatio>
  );
};

const NoCookiesVideoComponent = () => {
  const Link = useLinkComponent();
  const { t } = useTranslation();

  return (
    <PageGrid.Item gridColumn={{ initial: '1/-1', md: '1/-4' }}>
      <Stack gap="6">
        <Heading
          as={'h2'}
          size={{
            initial: 'S',
          }}
          color="inherit">
          {t('noCookies.title')}
        </Heading>
        <Text size={{ initial: 'BodyM', md: 'BodyL' }}>{t('noCookies.message')}</Text>
        <Stack.Item grow={true}>
          <Link href="#cookiepermissionmodal" linkType="anchor">
            <TextLink emphasis="high">{t('noCookies.button')}</TextLink>
          </Link>
        </Stack.Item>
      </Stack>
    </PageGrid.Item>
  );
};
