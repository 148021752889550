import { FC } from 'react';

import { Video as VideoComponent } from '@components/Video/Video';
import { wrap } from '@sitecore/common';
import type { VideoRendering } from '@sitecore/types/Video';
import { Stack, Text } from '@sparky';

const Video: FC<VideoRendering> = ({ fields }) => {
  if (!fields?.youtubeVideoId?.value && !fields?.youtubeVideoId?.editable) {
    return null;
  }

  return (
    <Stack gap="6">
      <VideoComponent title={fields?.youtubeVideoTitle?.value} videoId={fields.youtubeVideoId.value} />
      {fields?.captionText?.value || fields?.captionText?.editable ? (
        <Text size={{ md: 'BodyL' }} color="textLowEmphasis">
          {wrap(fields.captionText)}
        </Text>
      ) : null}
    </Stack>
  );
};

export default Video;
